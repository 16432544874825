<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Compute the following mathematical operations:</p>
      <p class="mb-2">
        <stemble-latex content="$(13.67\pm0.02)(120.4\pm0.2)\div4.623\pm0.006$" />
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{a)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$log(5.00\pm0.004)$" />
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{b)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$antilog[51.55(\pm0.08)]$" />
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-5"
        prepend-text="$\text{c)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$20.6 (\pm0.2) - 0.750(\pm0.008)\div0.0411(\pm0.002)$" />
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-5"
        prepend-text="$\text{d)}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUPEI2210A1Q3',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
};
</script>
